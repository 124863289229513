App.Modules = App.Modules || {};
App.Modules.desktopNavigation = function () {
	
	var openMobileMenu = function()
	{
		jQuery('.site-header .menu-primary-menu-container').slideDown(400).addClass('open');
		jQuery('body').addClass('no-scroll');
	};

	var closeMobileMenu = function(data) {
		jQuery('.site-header .menu-primary-menu-container').slideUp(400, function(){
			jQuery(this).removeClass('open');
			jQuery('body').removeClass('no-scroll');
		});
	};

	var openMobileSubmenu = function(data) 
	{
		data.event.preventDefault();
		jQuery(data.elem).parent().parent().children('ul.sub-menu').slideToggle();
	};
	
	var desktopMenu = function(data)
	{

			openMenu = function() {
				console.log('opendededed');
				jQuery(this).children('.sub-menu').slideDown(300);
			}

			closeMenu = function() {
				jQuery(this).children('.sub-menu').slideUp(400);
			}

			jQuery(data.elem).hoverIntent({
				over: openMenu,
				out: closeMenu,
				timeout: 25
			});
	};

	return {
		init: function() {	
			return this; 
		},
		events: function() {
			if( jQuery(window).width() >= 1025 ) {
				// DESKTOP
				//Events.bind('mouseover', '.site-header li.menu-item-has-children').to(desktopMenu, {context: this});
			} else {
				// MOBILE
				Events.bind('click', '.js-menu-toggle').to(openMobileMenu, {context: this});
				Events.bind('click', '.js-menu-close').to(closeMobileMenu, {context: this});
				Events.bind('click', '.js-open-submenu').to(openMobileSubmenu, {context: this});
			}
			return this;
		}
	};
}();