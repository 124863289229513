App.Modules = App.Modules || {};
App.Modules.stickyHeader = function () {
	
	 var adjustLayout = function()
	 { 
		//get header height
		headerHeight = jQuery('.site-header').outerHeight(true) - 2;
		
		//account for admin bar 
		if ( jQuery('body').hasClass('admin-bar') ) {

			//get admin bar height
			adminHeight = jQuery('#wpadminbar').outerHeight(true);

			//subtract admin bar height from header height
			headerHeight = headerHeight - adminHeight;
		}
		
		jQuery('.site-content').css({'paddingTop': headerHeight });
	 };

	var goSticky = function(data) {
		scroll = jQuery(window).scrollTop();
		if ( scroll > 50 ) {
		  jQuery('.site-header').addClass('sticky');
		} else {
		  jQuery('.site-header').removeClass('sticky');
		}
	};

	return {
		init: function() { 
			adjustLayout();
			return this; 
		},
		events: function() {
			Events.bind('scroll').to(goSticky, {context: this});
			return this;
		}
	};
}();