App.Modules = App.Modules || {};
App.Modules.miscScripts = function () {

var galleryFancybox = function(data) {

	jQuery('.gallery a').fancybox({
		// Options will go here
	});

	jQuery('a[href$="jpg"], a[href$="png"], a[href$="jpeg"]').fancybox();

}

var yearSlider = function(data) {

	if ( ! jQuery(data.elem).parent().next('.month').hasClass('open') ) {

		jQuery(data.elem).parent().next('.month').addClass('open');
		//jQuery(data.elem).parent().next('.month').slideUp();

	} else {

		jQuery(data.elem).parent().next('.month').removeClass('open');
		//jQuery(data.elem).parent().next('.month').slideDown();

	}

}

/*
 * =============================================================
 *
 * remember if user closed the donate nag box
 *
 * =============================================================
 */
	var closeFloatingBanner = function() {
		jQuery('.floating-banner').css({'bottom' : '-500px'});
		sessionStorage.setItem('floating-banner', 'closed');
	}

	var isBannerClosed = function() {
		jQuery(function() {
				if (sessionStorage.getItem('floating-banner') === 'closed') {
						jQuery('.floating-banner').addClass('closed');
				} else {
						jQuery('.floating-banner').removeClass('closed');
				}
		});
	}

/*
 * =============================================================
 *
 * open searchbox in fancybox
 *
 * =============================================================
 */
	var fancySearch = function()
	{
		// open the search in Fancybox
		// unhide it (it's on the page always, just hidden) when opened
		// hide it again when closed
		jQuery("a[href='#fancybox-search']").fancybox({
			fitToView: false,
			autoScale: false,
			autoSize: false,
			width: 500,
			height: 200,
			onStart: function() {
				// show the card block
				jQuery('#fancybox-search').show().css({'width' : '400px'});
			},
			beforeShow: function() {
			},
			onClosed: function() {
				// hide the card block
				jQuery('#fancybox-search').hide();
			}
		});
	};

	return {
		init: function() {
			isBannerClosed();
			fancySearch();
			galleryFancybox();
			return this; 
		},
		events: function() {
			Events.bind('click', '.js-close-floating-banner').to(closeFloatingBanner, {context: this});
			Events.bind('click', '.year i.fa').to(yearSlider, {context: this});

			return this;
		}
	};
}();